import { FC } from 'react';
import { MinimalTagResource } from '~/types';

import { NavLink } from '@remix-run/react';

import styles from './HotTopics.module.scss';

interface BaseProps {
  tags: MinimalTagResource[];
  isAutoDemo?: false;
  autoDemoVersion?: never;
}

interface AutoDemoProps {
  tags: MinimalTagResource[];
  isAutoDemo: true;
  autoDemoVersion: 1 | 2;
}

type Props = BaseProps | AutoDemoProps;

const renderLink = (
  tag: MinimalTagResource,
  isAutoDemo: boolean,
  autoDemoVersion?: 1 | 2,
) => {
  const basePath = isAutoDemo
    ? `auto-demo${autoDemoVersion === 2 ? '-2' : ''}`
    : 'discover';

  return (
    <NavLink
      key={tag.id}
      className={styles.topic}
      to={`/${basePath}/${tag.slug}`}
    >
      {tag.title}
    </NavLink>
  );
};

const HotTopics: FC<Props> = ({
  tags,
  isAutoDemo = false,
  autoDemoVersion,
}) => {
  return (
    <div className={styles.holder}>
      <h2 className={styles.heading}>Hot Topics</h2>
      <div className={styles.row}>
        {tags.map((tag) => renderLink(tag, isAutoDemo, autoDemoVersion))}
      </div>
    </div>
  );
};

export default HotTopics;
